<template>
    <div>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeName" label="所属线路"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="upTripNum" label="上行班次"></jy-table-column>
            <jy-table-column prop="downTripNum" label="下行班次"></jy-table-column>
            <jy-table-column prop="upMileage" label="上行里程"></jy-table-column>
            <jy-table-column prop="downMileage" label="下行里程"></jy-table-column>
            <jy-table-column prop="waybillMileage" label="路单里程"></jy-table-column>
            <jy-table-column prop="assistMileage" label="辅助里程"></jy-table-column>
            <jy-table-column prop="totalMileage" label="总里程"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            dataList: [],
            isFirst:true,
        }
    },
    methods: {
        init(option) {
            this.form = option
            this.pageIndex = 1
            this.getList()
        },
        getList() {
            this.isFirst = false
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            let url = '/stattripmileagedaily/queryVehicleTimeFrameTotal'
            this.$http.post(url, option).then(res => {
                if (res.retain == '1') {
                    this.dataList = []
                    this.$message({
                        message: res.error,
                        type: 'warning',
                    })
                } else {
                    this.dataList = res.detail.list
                    this.total = res.detail.total
                }
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getList()
        },
    },
}
</script>
